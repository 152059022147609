export default {
    purchase_order_statuses: {
        draft: 1,
        review: 2,
        request_changes: 3,
        changes_pending: 4,
        approved: 5,
        send_to_mfg: 6,
        confirmed: 7,
        closed: 8,
        cancelled: 9
    }
}